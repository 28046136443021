
import { defineComponent, computed } from 'vue'
export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    'update:modelValue': null,
    confirm: null
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })

    const close = () => {
      isShow.value = false
    }

    const cancel = () => {
      isShow.value = false
    }

    const confirm = () => {
      ct.emit('confirm', true)
    }
    return { isShow, close, cancel, confirm }
  }
})
