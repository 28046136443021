import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 服务商企业端订单页
export function userByOrderList(data?: RequestData) {
  return request({
    url: '/pla/service/order/userByOrderList',
    method: 'post',
    data
  })
}

// 服务商企业订单提交评价投诉相关
export function updateByOrder(data?: RequestData) {
  return request({
    url: '/pla/service/order/updateByOrder',
    method: 'post',
    data
  })
}

// 服务商企业订单在待处理时进行取消
export function cancelByUserOrder(data?: RequestData) {
  return request({
    url: '/pla/service/order/cancelByUserOrder',
    method: 'post',
    data
  })
}
