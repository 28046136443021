
import {
  defineComponent,
  computed,
  ref,
  getCurrentInstance,
  watchEffect
} from 'vue'
import JDialog from './dialog.vue'
import BaseInfoLine from './baseInfoLine.vue'
import { updateByOrder } from '@/api/order'

export default defineComponent({
  components: {
    BaseInfoLine,
    JDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    baseInfo: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      required: false,
      default: 'edit'
    }
  },
  emits: {
    confirm: null,
    'update:modelValue': null
  },
  setup(props, ct) {
    const ctx: any = (getCurrentInstance() as any).ctx
    const scoreValue = ref('')
    const evaluation = ref('')
    const rateTexts = ref([
      '糟糕',
      '失望',
      '一般',
      '比较满意，仍可改善',
      '非常满意'
    ])
    const isShow = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })
    watchEffect(() => {
      scoreValue.value = props.baseInfo.score
      evaluation.value = props.baseInfo.evaluation
    })
    const close = () => {
      isShow.value = false
    }

    const cancel = () => {
      isShow.value = false
    }

    const confirm = () => {
      if (!scoreValue.value) {
        ctx.$tips('请先评价')
        return
      }
      if (!evaluation.value) {
        ctx.$tips('请先评价')
        return
      }
      updateByOrder({
        id: props.baseInfo.id,
        evaluation: evaluation.value,
        score: scoreValue.value
      })
        .then(() => {
          ctx.$tips('提交成功')
          isShow.value = false
          ct.emit('confirm', true)
        })
        .catch(() => {
          // ctx.$tips(err.message)
        })
    }

    const isEdit = computed(() => {
      return props.type === 'edit'
    })
    return {
      isShow,
      isEdit,
      close,
      cancel,
      confirm,
      scoreValue,
      evaluation,
      rateTexts
    }
  }
})
