
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    baseInfo: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  setup() {
    return {}
  }
})
