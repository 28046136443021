<template>
  <j-dialog
    v-model="isShow"
    title="服务投诉"
    :btn-Show="isEdit"
    @confirm="confirm"
  >
    <base-info-line :base-info="baseInfo"></base-info-line>
    <p class="text-title">很抱歉，服务商没有给您提供满意的服务</p>
    <p class="text-des">
      平台服务质量监督员将在3个工作日对您电话回访，为您解决问题
    </p>
    <textarea
      v-model="complaint"
      class="input"
      name=""
      id=""
      cols="30"
      rows="10"
      placeholder="请说说您的服务体验~"
      :readonly="isEdit ? false : true"
    >
    </textarea>
  </j-dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  getCurrentInstance,
  watchEffect
} from 'vue'
import BaseInfoLine from './baseInfoLine.vue'
import JDialog from './dialog.vue'

import { updateByOrder } from '@/api/order'

export default defineComponent({
  components: {
    BaseInfoLine,
    JDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    baseInfo: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      required: false,
      default: 'edit'
    }
  },
  emits: {
    confirm: null,
    'update:modelValue': null
  },
  setup(props, ct) {
    const ctx: any = (getCurrentInstance() as any).ctx
    const complaint = ref('')
    const isShow = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })
    watchEffect(() => {
      complaint.value = props.baseInfo.complaint
    })
    const close = () => {
      isShow.value = false
    }

    const cancel = () => {
      isShow.value = false
    }

    const confirm = () => {
      if (!complaint.value) {
        ctx.$tips('请输入您的建议')
        return
      }
      updateByOrder({ id: props.baseInfo.id, complaint: complaint.value })
        .then(() => {
          ctx.$tips('提交成功')
          isShow.value = false
          ct.emit('confirm', true)
        })
        .catch(() => {
          // ctx.$tips(err.message)
        })
    }

    const isEdit = computed(() => {
      return props.type === 'edit'
    })
    return { isShow, isEdit, close, cancel, confirm, complaint }
  }
})
</script>

<style lang="scss">
.cdialog {
  border: 1px solid #ccc;
  border-radius: 10px;
  .el-dialog__header {
    padding: 0 !important;
  }
  .el-dialog__body {
    padding: 0px 18px 32px !important;
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top-bg {
    background: url('~@/assets/images/common/dialog_bg.png') no-repeat top
      center/cover;
    height: 91px;
    width: 210px;
  }

  .title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: -70px;
  }

  .text-title {
    margin-top: 65px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .text-des {
    margin-top: 9px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 23px;
    color: #999999;
  }

  .input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 15px;
    height: 91px;
    background: #f9f9f9;
    border-radius: 3px;
    padding: 11px 16px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    resize: none;
  }

  .table-tag {
    display: inline-block;
    margin-left: 8px;
    padding: 5px 7px;
    border: 1px solid #dbbb95;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c8a260;
  }

  .btn {
    padding: 0;
    text-align: center;
    margin-top: 32px;
    width: 292px;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
    box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
    border-radius: 25px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #230a01;
    user-select: none;
    cursor: pointer;
  }
}
</style>
