<template>
  <j-dialog
    v-model="isShow"
    title="服务评价"
    :btn-Show="isEdit"
    @confirm="confirm"
  >
    <base-info-line :base-info="baseInfo"></base-info-line>
    <p class="text-title">服务评价</p>
    <el-rate
      class="rate"
      v-model="scoreValue"
      :disabled="!isEdit"
      :show-text="true"
      :texts="rateTexts"
      text-color="#FC9153"
    ></el-rate>
    <p class="text-title text-title2" v-if="isEdit">
      说说您的服务体验，帮助平台更好的服务您
    </p>
    <textarea
      v-model="evaluation"
      class="input"
      name=""
      id=""
      cols="30"
      rows="10"
      placeholder="请说说您的服务体验~"
      :readonly="isEdit ? false : true"
    >
    </textarea>
  </j-dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  getCurrentInstance,
  watchEffect
} from 'vue'
import JDialog from './dialog.vue'
import BaseInfoLine from './baseInfoLine.vue'
import { updateByOrder } from '@/api/order'

export default defineComponent({
  components: {
    BaseInfoLine,
    JDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    baseInfo: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      required: false,
      default: 'edit'
    }
  },
  emits: {
    confirm: null,
    'update:modelValue': null
  },
  setup(props, ct) {
    const ctx: any = (getCurrentInstance() as any).ctx
    const scoreValue = ref('')
    const evaluation = ref('')
    const rateTexts = ref([
      '糟糕',
      '失望',
      '一般',
      '比较满意，仍可改善',
      '非常满意'
    ])
    const isShow = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })
    watchEffect(() => {
      scoreValue.value = props.baseInfo.score
      evaluation.value = props.baseInfo.evaluation
    })
    const close = () => {
      isShow.value = false
    }

    const cancel = () => {
      isShow.value = false
    }

    const confirm = () => {
      if (!scoreValue.value) {
        ctx.$tips('请先评价')
        return
      }
      if (!evaluation.value) {
        ctx.$tips('请先评价')
        return
      }
      updateByOrder({
        id: props.baseInfo.id,
        evaluation: evaluation.value,
        score: scoreValue.value
      })
        .then(() => {
          ctx.$tips('提交成功')
          isShow.value = false
          ct.emit('confirm', true)
        })
        .catch(() => {
          // ctx.$tips(err.message)
        })
    }

    const isEdit = computed(() => {
      return props.type === 'edit'
    })
    return {
      isShow,
      isEdit,
      close,
      cancel,
      confirm,
      scoreValue,
      evaluation,
      rateTexts
    }
  }
})
</script>

<style lang="scss">
.cdialog {
  .rate {
    width: 100%;
    height: 100px;
    padding: 23px 0 30px;

    .el-rate__icon {
      font-size: 35px;
    }
    .el-rate__text {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 22px;
      font-size: 14px;
    }
  }
  .text-title {
    margin-top: 65px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .text-title2 {
    margin-top: 45px;
  }

  .text-des {
    margin-top: 9px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 23px;
    color: #999999;
  }

  .input {
    box-sizing: border-box;
    width: 100%;
    margin-top: 15px;
    height: 91px;
    background: #f9f9f9;
    border-radius: 3px;
    padding: 11px 16px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    resize: none;
  }

  .table-tag {
    display: inline-block;
    margin-left: 8px;
    padding: 5px 7px;
    border: 1px solid #dbbb95;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c8a260;
  }

  .btn {
    padding: 0;
    text-align: center;
    margin-top: 32px;
    width: 292px;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
    box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
    border-radius: 25px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #230a01;
    user-select: none;
    cursor: pointer;
  }
}
</style>
