<template>
  <el-dialog
    custom-class="cdialog"
    v-model="isShow"
    width="378px"
    @close="close"
  >
    <div class="top">
      <div class="top-bg"></div>
      <div class="title">{{ title }}</div>
      <slot></slot>
      <span class="btn" @click="confirm" v-if="btnShow">{{ btnTxt }}</span>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    btnTxt: {
      type: String,
      required: false,
      default: '提交'
    },
    btnShow: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: {
    confirm: null,
    'update:modelValue': (isShow: boolean) => {
      return isShow === true || isShow === false
    }
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.modelValue
      },
      set(v: boolean) {
        ct.emit('update:modelValue', v)
      }
    })

    const close = () => {
      isShow.value = false
    }

    const confirm = () => {
      ct.emit('confirm')
    }

    return { isShow, close, confirm }
  }
})
</script>

<style lang="scss">
.cdialog {
  border: 1px solid #ccc;
  border-radius: 10px;
  .el-dialog__header {
    padding: 0 !important;
  }
  .el-dialog__body {
    padding: 0px 18px 32px !important;
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top-bg {
    background: url('~@/assets/images/common/dialog_bg.png') no-repeat top
      center/cover;
    height: 91px;
    width: 210px;
  }
}
</style>
