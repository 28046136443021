<template>
  <el-dialog
    custom-class="cancelDialog"
    v-model="isShow"
    width="378px"
    @close="close"
    :append-to-body="false"
  >
    <p class="text">确认取消订单吗？</p>
    <div class="btn-wrap">
      <span class="btn btn-cancel" @click="cancel">取消</span>
      <span class="btn btn-confim" @click="confirm">确定</span>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    'update:modelValue': null,
    confirm: null
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })

    const close = () => {
      isShow.value = false
    }

    const cancel = () => {
      isShow.value = false
    }

    const confirm = () => {
      ct.emit('confirm', true)
    }
    return { isShow, close, cancel, confirm }
  }
})
</script>

<style lang="scss">
.cancelDialog {
  border-radius: 10px;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 61px 47px 59px !important;
  }
  .text {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
}
</style>

<style lang="scss" scoped>
.btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 33px;
}
.btn {
  padding: 0;
  width: 102px;
  height: 48px;
  line-height: 48px;
  background: #f1f3f3;
  border: 1px solid #333333;
  border-radius: 24px;
  margin-right: 13px;
  font-size: 14px;
  color: #000;
  text-align: center;
  cursor: pointer;
  user-select: none;
  &:last-child {
    margin-right: 0;
  }
  &.btn-confim {
    background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%) !important;
    box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3) !important;
    border-color: transparent !important;
  }
}
</style>
