<template>
  <el-table :data="tableData" style="width: 100%" empty-text="暂无订单信息">
    <el-table-column label="" width="120">
      <template #default="scope">
        <div class="table-img">
          <img :src="scope.row.itemUrl" alt="" />
        </div>
      </template>
    </el-table-column>
    <el-table-column label="企业服务" width="250">
      <template #default="scope">
        <span style="margin-left: 10px"
          >{{ scope.row.typeName }}（{{ scope.row.specsName }}）</span
        >
      </template>
    </el-table-column>
    <el-table-column label="企业管家" width="250">
      <template #default="scope">
        <span>{{ scope.row.serviceName }}</span>
        <span class="table-tag" v-if="!scope.row.serviceId">专属企服管家</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center" width="350">
      <template #default="scope">
        <div v-if="scope.row.status === '已取消'">
          <el-popover
            placement="top-start"
            title="您可以拨打电话"
            trigger="hover"
            :content="scope.row.mobile"
          >
            <template #reference>
              <span class="option-item">立即联系</span>
            </template>
          </el-popover>
        </div>
        <div v-else>
          <span
            class="option-item"
            v-if="!scope.row.complaint && scope.row.serviceId"
            @click="clickHandler('evaluate', scope.row)"
            >投诉他</span
          >
          <span
            class="option-item"
            v-if="scope.row.complaint && scope.row.serviceId"
            @click="clickHandler('evaluate', scope.row, 'read')"
            >查看投诉</span
          >
          <span
            class="option-item"
            v-if="
              !scope.row.isCheck &&
                scope.row.status !== '已结束' &&
                'F' !== scope.row.transpose
            "
            @click="clickHandler('cancel', scope.row)"
            >取消订单</span
          >
          <span
            class="option-item"
            v-if="scope.row.status === '已完成' && !scope.row.score"
            @click="clickHandler('rate', scope.row)"
            >立即评价</span
          >
          <span
            class="option-item"
            v-if="scope.row.score"
            @click="clickHandler('rate', scope.row, 'read')"
            >查看评价</span
          >
          <el-popover
            placement="top-start"
            trigger="hover"
            title="您可以拨打电话"
            :content="scope.row.mobile"
          >
            <template #reference>
              <span class="option-item blue">立即联系</span>
            </template>
          </el-popover>
          <span
            v-if="scope.row.status === '已完成'"
            class="complate-sign"
          ></span>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  setup(props, ct) {
    const clickHandler = (clickType: string, row: any, type = 'edit') => {
      ct.emit('option', clickType, row, type)
    }
    return { clickHandler }
  }
})
</script>

<style lang="scss" scoped>
.table-img {
  width: 116px;
  height: 65px;
  background-color: #ccc;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.table-tag {
  display: inline-block;
  margin-left: 14px;
  padding: 5px 7px;
  border: 1px solid #dbbb95;
  border-radius: 2px;
  font-size: 12px;
  line-height: 1;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c8a260;
}

.complate-sign {
  background: url('~@/assets/images/common/order_finish.png') no-repeat
    center/cover;
  width: 63px;
  height: 52px;
  position: absolute;
  right: 0;
  top: 0;
}

.option-item {
  font-size: 14px;
  padding: 0 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;

  &.blue {
    color: #3c89ff;
  }
}
</style>
