
import {
  defineComponent,
  computed,
  ref,
  getCurrentInstance,
  watchEffect
} from 'vue'
import BaseInfoLine from './baseInfoLine.vue'
import JDialog from './dialog.vue'

import { updateByOrder } from '@/api/order'

export default defineComponent({
  components: {
    BaseInfoLine,
    JDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    baseInfo: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      required: false,
      default: 'edit'
    }
  },
  emits: {
    confirm: null,
    'update:modelValue': null
  },
  setup(props, ct) {
    const ctx: any = (getCurrentInstance() as any).ctx
    const complaint = ref('')
    const isShow = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })
    watchEffect(() => {
      complaint.value = props.baseInfo.complaint
    })
    const close = () => {
      isShow.value = false
    }

    const cancel = () => {
      isShow.value = false
    }

    const confirm = () => {
      if (!complaint.value) {
        ctx.$tips('请输入您的建议')
        return
      }
      updateByOrder({ id: props.baseInfo.id, complaint: complaint.value })
        .then(() => {
          ctx.$tips('提交成功')
          isShow.value = false
          ct.emit('confirm', true)
        })
        .catch(() => {
          // ctx.$tips(err.message)
        })
    }

    const isEdit = computed(() => {
      return props.type === 'edit'
    })
    return { isShow, isEdit, close, cancel, confirm, complaint }
  }
})
