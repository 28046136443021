<template>
  <div class="baseInfo">
    <div>
      <span>{{ baseInfo.serviceName }}</span>
      <span class="table-tag" v-if="!baseInfo.serviceId">专属企服管家</span>
    </div>
    <div class="baseInfo-type">{{ baseInfo.typeName }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    baseInfo: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  setup() {
    return {}
  }
})
</script>

<style lang="scss" scoped>
.baseInfo {
  margin-top: 23px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;

  &-type {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff5b60;
  }
}
</style>
