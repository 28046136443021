
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    btnTxt: {
      type: String,
      required: false,
      default: '提交'
    },
    btnShow: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: {
    confirm: null,
    'update:modelValue': (isShow: boolean) => {
      return isShow === true || isShow === false
    }
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.modelValue
      },
      set(v: boolean) {
        ct.emit('update:modelValue', v)
      }
    })

    const close = () => {
      isShow.value = false
    }

    const confirm = () => {
      ct.emit('confirm')
    }

    return { isShow, close, confirm }
  }
})
