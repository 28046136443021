
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  setup(props, ct) {
    const clickHandler = (clickType: string, row: any, type = 'edit') => {
      ct.emit('option', clickType, row, type)
    }
    return { clickHandler }
  }
})
