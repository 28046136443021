<template>
  <div class="order">
    <p class="order-title">企业服务订单</p>
    <el-tabs v-model="activeName">
      <el-tab-pane label="全部" name="1">
        <div class="order-tab_ct">
          <j-table :table-data="dealList" @option="optionHandler"></j-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已取消" name="2">
        <div class="order-tab_ct">
          <j-table :table-data="dealList" @option="optionHandler"></j-table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 取消订单 -->
    <cancel-order
      v-model="cancelStatus"
      @confirm="cancelConfirm"
    ></cancel-order>

    <evaluate
      v-model="evaluateStatus"
      :base-info="currentRow"
      :type="evaluateType"
      @confirm="getList"
    ></evaluate>

    <rate
      v-model="rateStatus"
      :base-info="currentRow"
      :type="rateType"
      @confirm="getList"
    ></rate>
  </div>
</template>

<script>
import { defineComponent, ref, computed, getCurrentInstance } from 'vue'
import { cancelByUserOrder, userByOrderList } from '@/api/order'
import CancelOrder from './components/cancelOrder.vue'
import Evaluate from './components/evaluate.vue'
import JTable from './components/table.vue'
import Rate from './components/rate.vue'
export default defineComponent({
  components: {
    CancelOrder,
    Evaluate,
    Rate,
    JTable
  },
  setup() {
    const instance = getCurrentInstance()
    const activeName = ref('1')
    const tableData = ref([])
    const cancelStatus = ref(false)
    const evaluateStatus = ref(false)
    const evaluateType = ref('edit')

    const rateStatus = ref(false)
    const rateType = ref('edit')

    const currentRow = ref({})

    const getList = () => {
      userByOrderList()
        .then(res => {
          if (res.status === '1') {
            tableData.value = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    const cancelOrder = () => {
      cancelStatus.value = true
    }

    const cancelConfirm = () => {
      cancelByUserOrder({ id: currentRow.value.id }).then(() => {
        instance.ctx.$tips('您的订单已取消')
        getList()
        cancelStatus.value = false
      })
    }

    const evaluateFn = (row, type = 'edit') => {
      evaluateType.value = type
      evaluateStatus.value = true
    }

    const rateFn = (row, type = 'edit') => {
      rateType.value = type
      rateStatus.value = true
    }
    const dealList = computed(() => {
      if (activeName.value === '1') {
        return tableData.value
      } else if (activeName.value === '2') {
        return tableData.value.filter(item => {
          return item.status === '已取消'
        })
      }
      return tableData
    })

    const optionHandler = (clickType, row, type) => {
      currentRow.value = row
      switch (clickType) {
        case 'cancel':
          cancelOrder(row, type)
          break
        case 'evaluate':
          evaluateFn(row, type)
          break
        case 'rate':
          rateFn(row, type)
          break
      }
    }
    getList()
    return {
      activeName,
      tableData,
      cancelOrder,
      cancelStatus,
      cancelConfirm,
      evaluateStatus,
      evaluateFn,
      dealList,
      currentRow,
      getList,
      evaluateType,
      rateStatus,
      rateType,
      rateFn,
      optionHandler
    }
  }
})
</script>

<style lang="scss" scoped>
.order {
  padding: 31px 33px 0;

  &-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #555555;
  }

  &-tab {
    &_ct {
      padding: 20px 0 0 0;
    }
  }

  :deep(.el-tabs__nav-wrap) {
    &::after {
      height: 1px;
      background-color: #f1f1f1;
    }
    .el-tabs__active-bar {
      height: 2px;
      background: #c8a260;
    }
    .el-tabs__item {
      margin: 0;
      padding: 0;
      min-width: 106px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;

      &.is-active {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #c8a260;
      }
    }
  }

  :deep(.el-table__header-wrapper) {
    table thead tr,
    table thead th {
      height: 42px;
      font-size: 14px;
      background: #f9f9f9;
    }
  }

  :deep(.el-table__body-wrapper) {
    table td {
      padding: 30px 0 !important;
    }
  }
}
</style>
